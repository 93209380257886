import {
  FontSizeVals,
  HeightVals,
  IColorProps,
  IntentVals,
  ITypographyProps,
  SemanticColorVals,
  TextColorVals,
} from '../../enhancers';

export type StrokeColorVals = 'text' | SemanticColorVals;

export interface IStrokeColorProps {
  stroke?: string;
  active?: {
    stoke?: string;
    color?: TextColorVals;
  };
}

export type RadioEnhancerProps = ITypographyProps & IColorProps & IStrokeColorProps;

export const variants: Record<IntentVals, Partial<RadioEnhancerProps>> = {
  default: {
    stroke: 'var(--color-text)',
    active: {
      stoke: 'var(--color-primary)',
    },
  },
  success: {
    stroke: 'var(--color-success)',
    color: 'success-dark',
    active: {
      stoke: 'var(--color-success)',
    },
  },
  warning: {
    stroke: 'var(--color-warning)',
    color: 'warning-dark',
    active: {
      stoke: 'var(--color-warning)',
    },
  },
  danger: {
    stroke: 'var(--color-danger)',
    color: 'danger-dark',
    active: {
      stoke: 'var(--color-danger)',
    },
  },
};

export type SizeEnhancerProps = Record<
  HeightVals,
  {
    svgHeight: number;
    svgWidth: number;
    svgStrokeWidth: number;
    fontSize: FontSizeVals;
  }
>;

export const sizes: Partial<SizeEnhancerProps> = {
  lg: {
    fontSize: 'xl',
    svgHeight: 16,
    svgWidth: 16,
    svgStrokeWidth: 2,
  },
  md: {
    fontSize: 'lg',
    svgHeight: 14,
    svgWidth: 14,
    svgStrokeWidth: 2,
  },
  sm: {
    fontSize: 'base',
    svgHeight: 12,
    svgWidth: 12,
    svgStrokeWidth: 2,
  },
};
