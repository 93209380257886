import { CollectionChildren } from '@react-types/shared';
import * as React from 'react';
import { memo } from 'react';

import { Box } from '../Box';
import { Flex } from '../Flex';
import { Icon } from '../Icon';

type StepsProps = {
  current?: number;
  className?: string;
};

type StepProps = {
  title: string;
  className?: string;
  index?: number;
  isActive?: boolean;
  completed?: boolean;
  isLast?: boolean;
};

export const Step = ({ title, index, isActive, completed, className, isLast }: StepProps) => {
  return (
    <Flex flex={isLast ? 'none' : 'initial'} flexGrow={isLast ? 0 : true} alignItems="center" className={className}>
      <Box
        pos="relative"
        style={{ color: isActive || completed ? 'var(--color-primary-dark)' : 'var(--color-text-disabled)' }}
      >
        <Flex
          bg={isActive ? 'primary-tint' : completed ? 'primary-dark' : 'canvas-100'}
          justifyContent="center"
          alignItems="center"
          w={10}
          h={10}
          rounded="full"
          color={completed ? 'on-primary' : null}
        >
          {completed ? <Icon icon="check" /> : <span>{index}</span>}
        </Flex>
        <Flex
          pos="absolute"
          textAlign="center"
          left={-10}
          top={10}
          h={10}
          style={{ width: 120 }}
          alignItems="center"
          justifyContent="center"
        >
          {title}
        </Flex>
      </Box>
      <Flex flexGrow h={0.5} bg={completed ? 'primary-dark' : 'canvas-100'}></Flex>
    </Flex>
  );
};

export const Steps = memo(function Steps(props: StepsProps & { children: CollectionChildren<object> }) {
  const items = React.Children.toArray(props.children);
  const current = props.current || 0;

  const childrenWithProps = items.map((child, i) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        index: i + 1,
        isActive: current === i,
        completed: i < current,
        isLast: i === items.length - 1,
      });
    }
    return child;
  });

  return (
    <Flex w="full" fontSize="base" px={10} pb={10} className={props.className}>
      {childrenWithProps}
    </Flex>
  );
});
