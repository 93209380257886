import { AriaMenuOptions } from '@react-aria/menu';
import * as React from 'react';
import { useCallback, useMemo } from 'react';

import { useThemeIsDark } from '../../hooks';
import { Box } from '../Box';
import { Button } from '../Button';
import { Item, Section } from '../Collections';
import { Input } from '../Input';
import { NoSsr } from '../NoSsr';
import { HStack, VStack } from '../Stack';
import { isMenuDivider, isMenuGroup, isMenuOption, isMenuOptionGroup } from './guards';
import { MenuContext, MenuContextProps } from './MenuContext';
import { MenuKeyboardDelegate } from './MenuKeyboardDelegate';
import { MenuNodes, menuNodesWidthStyles } from './MenuNodes';
import { GenericMenuItem, MenuItem, MenuItems, MenuOptionItem } from './types';
import { useMenuState } from './useMenuState';

export type MenuListProps = {
  /** The object describing the items to show in the menu. */
  items: MenuItems;

  /**
   * Called when the Menu should close.
   */
  onClose?: () => void;

  /**
   * By default the Menu will only close when a non checkable `MenuItem` is pressed. Setting `closeOnPress` to true will cause
   * the menu to close when any `MenuItem` or `MenuOption` is pressed. Can still be overriden at the menu item level.
   * @default false
   */
  closeOnPress?: boolean;

  /**
   * Adjusts the overall size of the menu and its contents.
   */
  size?: MenuContextProps['size'];

  /**
   * Adjusts the default cursor when user hovers over items in the menu.
   */
  cursor?: MenuContextProps['cursor'];

  /**
   * Show a text input that will filter the menu items based on the text entered.
   */
  showFilter?: boolean;

  /**
   * Called when the user types a novel value into the filter input and presses enter.
   * (Typically used to create a new item in the menu.)
   */
  onFilterEnterKey?: (value: string) => void;
} & Pick<AriaMenuOptions<any>, 'aria-label'>;

const match = (item: MenuItem | undefined | null | false, filterString: string): boolean => {
  return !filterString || !item || !('title' in item) || !item.title || item.title.toLowerCase().includes(filterString);
};

const isChildrenEmpty = (children: GenericMenuItem[]): boolean =>
  children.length === 0 || children.every(child => child === null || isMenuDivider(child));

const filterItems = (items: MenuItems, lowercasedFilterString: string): GenericMenuItem[] => {
  return items.map(item => {
    if (!item) return item;
    if ('children' in item && item.children) {
      const children = filterItems(item.children as MenuItems, lowercasedFilterString);
      if (isChildrenEmpty(children)) {
        return null;
      } else {
        return {
          ...item,
          children: filterItems(item.children as MenuItems, lowercasedFilterString),
        };
      }
    } else {
      return match(item, lowercasedFilterString) ? item : null;
    }
  }) as GenericMenuItem[];
};

export function MenuList({
  items,
  onClose,
  closeOnPress,
  size,
  cursor,
  showFilter,
  onFilterEnterKey,
  ...props
}: MenuListProps) {
  const [filterString, setFilterString] = React.useState('');
  const lowercasedFilterString = filterString.toLowerCase();

  const filteredItems = filterItems(items, lowercasedFilterString);
  const filteredItemsEmpty = isChildrenEmpty(filteredItems);
  const state = useMenuState({
    items: filteredItems,
    children: MenuListChildren,
  });

  const keyboardDelegate = useMemo(() => new MenuKeyboardDelegate(state), [state]);

  const handleOnClose = useCallback(() => {
    // make sure to collapse all submenus when menu close is triggered
    state.collapseAllKeys();

    if (onClose) onClose();
  }, [onClose, state]);

  const isDark = useThemeIsDark();

  const handleFilterCreate = React.useCallback(() => {
    const actualValue = filterString.trim();
    if (actualValue.length === 0) return;
    onFilterEnterKey?.(actualValue);
    handleOnClose();
  }, [filterString, onFilterEnterKey, handleOnClose]);

  const handleKeyUp = React.useCallback<React.KeyboardEventHandler>(
    e => {
      if (e.key === 'Enter') handleFilterCreate();
    },
    [handleFilterCreate],
  );

  return (
    <NoSsr>
      <MenuContext.Provider
        value={{
          state,
          keyboardDelegate,
          closeOnPress,
          onClose: handleOnClose,
          size,
          cursor,
        }}
      >
        <VStack>
          {showFilter ? (
            <Box
              bg={isDark ? 'canvas-dialog' : 'canvas-pure'}
              w="full"
              style={menuNodesWidthStyles}
              p={size === 'lg' ? 3 : 2}
              cursor
              overflowY="auto"
              display="inline-block"
              flexShrink={0}
              noFocusRing
            >
              <HStack>
                <Input
                  flex={1}
                  placeholder="Filter"
                  enterKeyHint="enter"
                  title='Press "Enter" to create an item not in the list.'
                  value={filterString}
                  onChange={e => setFilterString(e.currentTarget.value)}
                  onKeyUp={handleKeyUp}
                />
                {onFilterEnterKey && filteredItemsEmpty ? (
                  <Button
                    icon="plus"
                    title='Press "Enter" to create an item not in the list.'
                    appearance="minimal"
                    onPress={handleFilterCreate}
                  ></Button>
                ) : null}
              </HStack>
            </Box>
          ) : null}
          <MenuNodes {...props} className="sl-menu" autoFocus={!showFilter} items={state.collection} />
        </VStack>
      </MenuContext.Provider>
    </NoSsr>
  );
}

function MenuOption(item: MenuOptionItem) {
  return <Item key={item.id || item.value || item.title} id={item.id || item.value} title={item.value || item.title} />;
}

let idCounter = 1;
function MenuListChildren(item: GenericMenuItem) {
  if (isMenuGroup(item)) {
    return (
      <Section
        key={item.id || item.title}
        id={item.id}
        title={item.title}
        // @ts-expect-error
        items={item.children || []}
        // eslint-disable-next-line react/no-children-prop
        children={MenuListChildren}
      />
    );
  }

  if (isMenuOptionGroup(item)) {
    return (
      <Section
        key={item.id || item.title}
        id={item.id}
        title={item.title}
        items={item.children || []}
        // eslint-disable-next-line react/no-children-prop
        children={MenuOption}
      />
    );
  }

  if (isMenuOption(item)) {
    return MenuOption(item);
  }

  if (isMenuDivider(item)) {
    return <Item key={idCounter++} />;
  }

  return (
    <Item
      key={item.id || item.title}
      id={item.id}
      title={item.title}
      childItems={item.children}
      hasChildItems={!!(item.children && item.children.length)}
      // eslint-disable-next-line react/no-children-prop
      children={MenuListChildren}
    />
  );
}
