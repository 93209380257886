import cn from 'clsx';
import * as React from 'react';
import { forwardRef, memo, RefObject } from 'react';

import { BorderProps, IMarginProps, IPaddingProps } from '../../enhancers';
import { PolymorphicComponentProps } from '../Box';
import { Flex } from '../Flex';

export type FormGroupOwnProps = IPaddingProps &
  IMarginProps &
  BorderProps & {
    className?: string;
  };

export type FormGroupProps<E extends React.ElementType = typeof defaultElement> = PolymorphicComponentProps<
  E,
  FormGroupOwnProps
>;

const defaultElement = 'div';

export const FormGroup: <E extends React.ElementType = typeof defaultElement>(
  props: FormGroupProps<E> & { ref?: RefObject<HTMLDivElement> },
) => JSX.Element = memo(
  forwardRef(function ButtonGroup<E extends React.ElementType>(
    { children, className, ...rest }: FormGroupProps<E>,
    ref: RefObject<HTMLDivElement>,
  ) {
    return (
      <Flex
        as={defaultElement}
        className={cn('sl-form-group', className)}
        border
        rounded
        {...rest}
        bg={rest.bg || 'canvas'}
        justifyItems="start"
        alignItems="center"
        ref={ref}
      >
        {children}
      </Flex>
    );
  }),
);
