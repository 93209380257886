import cn from 'clsx';
import * as React from 'react';
import { forwardRef, memo } from 'react';

import { FontSizeVals } from '../../enhancers';
import { Avatar, AvatarProps } from '../Avatar';
import { Box } from '../Box';
import { Flex } from '../Flex';
import { Icon, IIconProps } from '../Icon';
import { Text } from '../Text';

const sizes: Record<'md' | 'lg', { avatarSize: AvatarProps['size']; fontSize?: FontSizeVals }> = {
  lg: {
    avatarSize: 'md',
    fontSize: 'lg',
  },
  md: {
    avatarSize: 'sm',
  },
};

type BaseEntityNameProps = {
  name: string;
  className?: string;
  isHeading?: boolean;
  size?: 'md' | 'lg';
  avatar?: AvatarProps;
};

type IconEntityNameProps = BaseEntityNameProps & {
  icon: IIconProps['icon'];
};

type AvatarEntityNameProps = BaseEntityNameProps & {
  avatar: AvatarProps;
};

export type EntityNameProps = BaseEntityNameProps | IconEntityNameProps | AvatarEntityNameProps;

export const EntityName = memo(
  forwardRef<HTMLDivElement, EntityNameProps>(function EntityName(props, ref) {
    const { className, name, isHeading, size = 'md', avatar, ...rest } = props;

    let leftElem;
    if (isIconEntityName(props)) {
      leftElem = (
        <Box mx="auto">
          <Icon icon={props.icon} />
        </Box>
      );
    } else if (isAvatarEntityName(props)) {
      leftElem = <Avatar size={sizes[size].avatarSize} mx="auto" {...avatar} />;
    }

    return (
      <Flex {...rest} ref={ref} align="center" className={cn('sl-entity-name', className)}>
        {leftElem ? (
          <Box w={12} ml={-3}>
            {leftElem}
          </Box>
        ) : null}

        <Text fontWeight={isHeading ? 'semibold' : 'normal'} size={sizes[size].fontSize}>
          {name}
        </Text>
      </Flex>
    );
  }),
);

function isIconEntityName(props: EntityNameProps): props is IconEntityNameProps {
  return (props as IconEntityNameProps).icon !== undefined;
}

function isAvatarEntityName(props: EntityNameProps): props is AvatarEntityNameProps {
  return (props as AvatarEntityNameProps).avatar !== undefined;
}
