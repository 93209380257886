import cn from 'clsx';
import * as React from 'react';
import { memo } from 'react';

import { BoxProps } from '../Box';
import { Container } from '../Container';

export type ArticleProps = BoxProps<any>;

export const Article = memo(function Article({ className, children, ...props }: ArticleProps) {
  return (
    <Container as="article" className={cn('sl-article', className)} size="md" py={20} {...props}>
      {children}
    </Container>
  );
});
