import * as React from 'react';
import { forwardRef, memo } from 'react';

import { FontSizeVals, ParagraphSizes } from '../../enhancers';
import { Box, PolymorphicComponentProps } from '../Box';

export type TextSizeVals = FontSizeVals | ParagraphSizes;
export type TextOwnProps = {
  size?: TextSizeVals; // alias for fontSize, can do more with it later like control letter spacing, etc
};

export type TextProps<E extends React.ElementType = typeof defaultElement> = PolymorphicComponentProps<E, TextOwnProps>;

const defaultElement = 'span';

export const Text: <E extends React.ElementType = typeof defaultElement>(props: TextProps<E>) => JSX.Element = memo(
  forwardRef(function Text<E extends React.ElementType>(
    { size, ...restProps }: TextProps<E>,
    ref: typeof restProps.ref,
  ) {
    return <Box as={defaultElement} ref={ref} fontSize={size} {...restProps} />;
  }),
);
