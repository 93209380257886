import { useEffect, useState } from 'react';

type MountTransitionProps = {
  isMounted: boolean;
  unmountDelay: number;
};

export const useMountTransition = ({ isMounted, unmountDelay }: MountTransitionProps) => {
  const [isTransitioning, setIsTransitioning] = useState<boolean>(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (isMounted && !isTransitioning) {
      setIsTransitioning(true);
    } else if (!isMounted && isTransitioning) {
      timeoutId = setTimeout(() => setIsTransitioning(false), unmountDelay);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [unmountDelay, isMounted, isTransitioning]);

  return isTransitioning;
};
