import cn from 'clsx';
import * as React from 'react';
import { Key, ReactElement, ReactNode } from 'react';
import { OverflowList } from 'react-overflow-list';

import { BorderProps, IMarginProps } from '../../enhancers';
import { Avatar, AvatarProps, sizes as avatarSizes } from '../Avatar/Avatar';
import { IBoxHTMLAttributes } from '../Box';
import { Flex } from '../Flex';
import { HStack, StackProps } from '../Stack';

export type AvatarListItemProps = Pick<AvatarProps, 'fg' | 'bg' | 'name' | 'src' | 'icon' | 'isInverted'>;

export type AvatarListProps = IMarginProps &
  Pick<BorderProps, 'border' | 'borderColor'> & {
    /** The size of the avatars. */
    size?: AvatarProps['size'];

    /** Displays the avatar colors reversed. */
    isInverted?: boolean;

    /** The avatars to show */
    items?: AvatarListItemProps[];

    /**
     * Do not set a title or alt attribute. Useful if handling this another way, for example with a wrapping tooltip.
     */
    noTitleAlt?: boolean;

    /** The minimum number of avatars to show */
    minVisibleItems?: number;

    /** The maximum number of avatars to show. If this is set, the dynamic limiting will be disabled */
    maxVisibleItems?: number;

    /** The spacing between the avatars */
    spacing?: StackProps['spacing'];

    /** Customize the rendering of the item, including wrapping the item. */
    itemRendererOverride?: (itemProps: AvatarListItemProps, key: Key, itemElem: ReactElement) => ReactNode;

    /** Customize the rendering of the overflow item, including wrapping the item. */
    overflowRendererOverride?: (overflowItemProps: AvatarListItemProps[], overflowElem: ReactElement) => ReactNode;
  };

export const AvatarList = function AvatarList(props: AvatarListProps) {
  const { size = 'md', isInverted, noTitleAlt, spacing = 1, minVisibleItems, maxVisibleItems, items } = props;

  const itemRenderer = (itemProps: AvatarListItemProps, key: Key) => {
    const itemElem = <Avatar {...itemProps} size={size} key={key} />;

    return props.itemRendererOverride ? props.itemRendererOverride(itemProps, key, itemElem) : itemElem;
  };

  const WrapperElem = React.useMemo(
    () =>
      React.forwardRef(function WrapperElem(wrapperProps, wrapperRef: any) {
        return <HStack {...wrapperProps} spacing={spacing} ref={wrapperRef} />;
      }),
    [spacing],
  );

  const overflowRenderer = (overflowItemProps: AvatarListItemProps[]) => {
    let color = 'var(--color-text)';
    let backgroundColor = 'var(--color-canvas)';

    if (isInverted) {
      [color, backgroundColor] = [backgroundColor, color];
    }

    const overflowNodeProps: IBoxHTMLAttributes = {
      style: {
        border: '1px solid',
        borderColor: color,
        color,
        backgroundColor,
        width: avatarSizes[size]?.[0],
        height: avatarSizes[size]?.[0],
      },
    };

    const overflowElem = (
      <Flex
        className={cn('sl-avatar')}
        align="center"
        justify="center"
        title={noTitleAlt && String(overflowItemProps.length)}
        fontSize={avatarSizes[size]?.[1]}
        rounded="full"
        lineHeight="none"
        fontWeight="semibold"
        userSelect="none"
        flexShrink={0}
        {...overflowNodeProps}
      >
        +{overflowItemProps.length}
      </Flex>
    );

    return props.overflowRendererOverride
      ? props.overflowRendererOverride(overflowItemProps, overflowElem)
      : overflowElem;
  };

  const innerItems = props.items?.map(i => ({ ...i, isInverted: i.isInverted ?? isInverted, noTitleAlt })) ?? [];

  if (typeof maxVisibleItems !== 'number' || maxVisibleItems < 0) {
    return (
      <OverflowList
        items={innerItems}
        itemRenderer={itemRenderer}
        overflowRenderer={overflowRenderer}
        minVisibleItems={minVisibleItems}
        // @ts-expect-error incorrect typing... it accepts any elem
        tagName={WrapperElem}
      />
    );
  } else {
    const visibleItems = innerItems.slice(0, maxVisibleItems);
    const overflowItems = innerItems.slice(maxVisibleItems);

    return React.createElement(WrapperElem, {}, [
      ...visibleItems.map(itemRenderer),
      overflowItems.length > 0 ? overflowRenderer(overflowItems) : null,
    ]);
  }
};
