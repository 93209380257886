import cn from 'clsx';
import * as React from 'react';
import { forwardRef, memo } from 'react';

import { computeResponsiveClasses, IFlexProps, IFlexShorthandProps, Responsive, SpaceVals } from '../../enhancers';
import { isObject } from '../../utils/assertion';
import { Box, PolymorphicComponentProps } from '../Box';
import { Flex } from '../Flex';

export type StackSpacingVals = 1 | 2 | 3 | 4 | 5 | 6 | 8 | 10 | 12 | 14 | 16 | 20 | 24 | 32;

type DirectionVals = 'vertical' | 'horizontal';

export type StackOwnProps = Pick<IFlexShorthandProps, 'align' | 'justify' | 'wrap'> & {
  /**
   * The direction to render the stack
   */
  direction?: DirectionVals | Responsive<DirectionVals>;

  /**
   * The space between each stack item
   */
  spacing?: StackSpacingVals | Responsive<StackSpacingVals>;

  /**
   * If `true`, each stack item will show a divider
   */
  divider?: true | React.ReactElement;

  /**
   * If `true`, will render as `inline-flex` rather than `flex`
   */
  inline?: boolean;
};

export type StackProps<E extends React.ElementType = typeof defaultElement> = PolymorphicComponentProps<
  E,
  StackOwnProps
>;

const defaultElement = 'div';

const directionToFlex: Record<DirectionVals, IFlexProps['flexDirection']> = {
  vertical: 'col',
  horizontal: 'row',
};

const convertToFlexDirection = direction => {
  if (isObject(direction)) {
    for (const [key, value] of Object.entries(direction)) {
      direction[key] = directionToFlex[value];
    }
    return direction;
  }

  return directionToFlex[direction];
};

export const Stack: <E extends React.ElementType = typeof defaultElement>(props: StackProps<E>) => JSX.Element = memo(
  forwardRef(function Stack<E extends React.ElementType>(
    { className, spacing, direction = 'vertical', divider, children, ...restProps }: StackProps<E>,
    ref: typeof restProps.ref,
  ) {
    const hasDivider = !!divider;

    const _className = cn(
      'sl-stack',
      {
        [computeResponsiveClasses('stack-', direction)]: spacing !== void 0,
        [computeResponsiveClasses<StackOwnProps['spacing']>('stack-', spacing)]: spacing !== void 0,
      },

      className,
    );

    let clones = children;
    const childrenWithoutNulls = React.Children.toArray(children).filter(Boolean);
    if (childrenWithoutNulls && hasDivider) {
      const childCount = childrenWithoutNulls.length;
      clones = React.Children.map(childrenWithoutNulls, (child, index) => {
        const isLast = index + 1 === childCount;

        const clonedDivider =
          typeof divider === 'boolean' ? (
            <Box
              key="d"
              borderT={direction === 'vertical' ? true : undefined}
              borderL={direction === 'horizontal' ? true : undefined}
              alignSelf="stretch"
            />
          ) : (
            React.cloneElement(divider as any, { key: 'd' })
          );

        const _divider = isLast ? null : clonedDivider;

        return <React.Fragment key={index}>{[child, _divider]}</React.Fragment>;
      });
    }

    return (
      <Flex
        as={defaultElement}
        ref={ref}
        className={_className}
        direction={convertToFlexDirection(direction)}
        {...restProps}
      >
        {clones}
      </Flex>
    );
  }),
);

export const HStack: <E extends React.ElementType = typeof defaultElement>(props: StackProps<E>) => JSX.Element =
  forwardRef(function HStack<E extends React.ElementType>(props: StackProps<E>, ref) {
    return <Stack align="center" {...props} ref={ref} direction="horizontal" />;
  });

export const VStack: <E extends React.ElementType = typeof defaultElement>(props: StackProps<E>) => JSX.Element =
  forwardRef(function VStack<E extends React.ElementType>(props: StackProps<E>, ref) {
    return <Stack align="stretch" {...props} ref={ref} direction="vertical" />;
  });
