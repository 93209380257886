import { useFocusRing } from '@react-aria/focus';
import { RadioAriaProps, useRadio } from '@react-aria/radio';
import { RadioProps } from '@react-types/radio';
import cn from 'clsx';
import * as React from 'react';
import { forwardRef, RefObject, useRef } from 'react';

import { HeightVals, IntentVals } from '../../enhancers';
import { Box } from '../Box';
import { Flex } from '../Flex';
import { VisuallyHidden } from '../VisuallyHidden';
import { useRadioContext } from './context';
import { RadioEnhancerProps, sizes, variants } from './variants';

interface IRadioProps extends RadioAriaProps, RadioProps {
  variant?: Partial<RadioEnhancerProps>;
  name?: string;
  size?: HeightVals;

  isDisabled?: boolean;

  /** @deprecated prefer isDisabled */
  disabled?: boolean;

  intent?: IntentVals;
}

export const Radio = forwardRef(function Radio({ disabled, ...props }: IRadioProps, ref: RefObject<HTMLInputElement>) {
  const { children } = props;
  const isDisabled = props.isDisabled !== void 0 ? props.isDisabled : disabled;
  const state = useRadioContext();
  const fallbackRef = useRef();
  const inputRef = ref || fallbackRef;
  const { inputProps } = useRadio({ ...props, isDisabled }, state, inputRef);
  const { name, size, intent } = props;
  const { isFocusVisible, focusProps } = useFocusRing();

  const isSelected = state.selectedValue === props.value;
  const strokeWidth = isSelected ? sizes[size].svgStrokeWidth * 2 : sizes[size].svgStrokeWidth;
  inputProps.name = name ?? inputProps.name;

  return (
    <Flex
      as="label"
      alignItems="center"
      className={cn('sl-radio-group__radio', { 'sl-radio-group__radio--disabled': isDisabled })}
    >
      <VisuallyHidden>
        <input {...inputProps} {...focusProps} ref={inputRef} />
      </VisuallyHidden>

      <Flex flexDirection={'col'} justifyContent={'center'}>
        <svg
          width={sizes[size].svgWidth}
          height={sizes[size].svgHeight}
          aria-hidden="true"
          style={{ marginRight: 4 }}
          className={isFocusVisible ? 'sl-svg-focus' : ''}
        >
          <circle
            cx={sizes[size].svgWidth / 2}
            cy={sizes[size].svgHeight / 2}
            r={sizes[size].svgHeight / 3 - 1}
            fill="var(--color-canvas)"
            strokeWidth={strokeWidth}
            stroke={isSelected ? variants[intent].active.stoke : variants[intent].stroke}
          />
        </svg>
      </Flex>

      <Box fontSize={sizes[size].fontSize} fontWeight={'semibold'}>
        {children}
      </Box>
    </Flex>
  );
});
