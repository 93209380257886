const isServer = typeof document === 'undefined';

export const findCss = (id: string): HTMLStyleElement => {
  if (isServer) return;

  return document.head.querySelector('#' + id);
};

export const appendCss = (id: string, css: string) => {
  if (isServer) return;

  // only inject once
  if (!findCss(id)) {
    const node = document.createElement('style');
    node.textContent = css;
    node.type = 'text/css';
    node.id = id;

    document.head.appendChild(node);
  }
};

export const replaceCss = (id: string, css: string) => {
  if (isServer) return;

  let node: HTMLStyleElement = findCss(id);
  if (!node) {
    node = document.createElement('style');
    node.type = 'text/css';
    node.id = id;
    node.textContent = css;

    document.head.appendChild(node);
  } else {
    node.textContent = css;
  }
};
