/**
 * Method to split the dataset props from other props.
 *
 * Useful for when you want to pass all of the dataset props to a different child than the other props.
 */
export function splitDatasetProps<P extends {}>(props: P) {
  const matchedProps = {} as Pick<P, keyof P & `data-${string}`>;
  const remainingProps = {} as P;

  for (const key in props) {
    if (startsWith(key, 'data-')) {
      matchedProps[key] = props[key];
    } else {
      remainingProps[key] = props[key];
    }
  }
  return { matchedProps, remainingProps };
}

/**
 * A type guard to check if a string starts with a prefix.
 * @param str the string to check
 * @param prefix the (const) prefix to check for
 * @returns boolean, but more importantly narrows the type of the string
 */
function startsWith<T extends string>(str: string, prefix: T): str is `${T}string` {
  return str.startsWith(prefix);
}
