import * as React from 'react';
import { forwardRef, memo } from 'react';

import { IFlexProps, IFlexShorthandProps } from '../../enhancers/flex';
import { Box, DatasetProps, PolymorphicComponentProps } from '../Box';

export type FlexOwnProps = IFlexShorthandProps & {
  /**
   * If `true`, will render as `inline-flex` rather than `flex`
   */
  inline?: boolean;
};

export type FlexProps<E extends React.ElementType = typeof defaultElement> = PolymorphicComponentProps<E, FlexOwnProps>;

const defaultElement = 'div';

export const Flex: <E extends React.ElementType = typeof defaultElement>(props: FlexProps<E>) => JSX.Element = memo(
  forwardRef(function Flex<E extends React.ElementType>(
    { align, justify, wrap, direction, grow, shrink, inline, ...restProps }: FlexProps<E>,
    ref: typeof restProps.ref,
  ) {
    const remapped: Partial<IFlexProps> = {
      alignItems: align,
      justifyContent: justify,
      flexWrap: wrap,
      flexDirection: direction,
      flexGrow: grow,
      flexShrink: shrink,
    };

    return <Box as={defaultElement} ref={ref} display={inline ? 'inline-flex' : 'flex'} {...remapped} {...restProps} />;
  }),
);
