import { useHover } from '@react-aria/interactions';
import { AriaMenuOptions, useMenu, useMenuItem, useMenuSection } from '@react-aria/menu';
import { useSeparator } from '@react-aria/separator';
import { mergeProps } from '@react-aria/utils';
import { Virtualizer } from '@react-aria/virtualizer';
import { ListLayout } from '@react-stately/layout';
import { Collection } from '@react-types/shared';
import cn from 'clsx';
import * as React from 'react';
import { Key, RefObject, useCallback, useContext, useRef } from 'react';

import { useId, useInteractionModality, useMosaicContext, useThemeIsDark } from '../../hooks';
import { Box } from '../Box';
import { Popover } from '../Popover';
import { isMenuDivider, isMenuGroup, isMenuNodeCollection, isMenuOption, isMenuOptionGroup } from './guards';
import { MenuNode } from './MenuCollection';
import { MenuContext, MenuContextProps } from './MenuContext';
import { Internal_MenuItemRow, MenuItemRowProps } from './MenuItemRow';
import { MenuGroup, MenuItem, MenuOptionGroup, MenuOptionItem } from './types';
import { MenuState } from './useMenuState';

export const menuNodesWidthStyles = { minWidth: 150, maxWidth: 400 };

export function MenuNodes({
  items,
  autoFocus,
  className,
  ...props
}: {
  items: Iterable<any>;
  autoFocus?: boolean;
  className?: string;
} & Omit<AriaMenuOptions<any>, 'children'>) {
  const ref = useRef();
  const isDark = useThemeIsDark();
  const { state, keyboardDelegate, size } = useContext(MenuContext);

  const isRoot = isMenuNodeCollection(items) && items.getFirstKey() === '0';

  const {
    // pull color out for typing reasons
    // pull onBlur and onFocus out because react-aria isn't setup to work with portaled submenus
    menuProps: { color, onBlur: _onBlur, onFocus, ...menuProps },
  } = useMenu(
    // @ts-expect-error children is not needed
    {
      ...props,
      autoFocus,
      keyboardDelegate,
      isVirtualized: true,
    },
    state,
    ref,
  );

  const modality = useInteractionModality();
  const pointerInteraction = modality === 'pointer';

  let layout = React.useMemo(
    () =>
      new ListLayout({
        rowHeight: 26,
        padding: 8,
      }),
    [],
  );

  layout.collection = state.collection;

  return isRoot ? (
    <Virtualizer
      ref={ref}
      style={{
        ...menuNodesWidthStyles,
        background: isDark ? 'var(--color-canvas-dialog)' : 'var(--color-canvas-pure)',
        width: '100%',
      }}
      className={cn(className, { 'sl-menu--pointer-interactions': pointerInteraction })}
      {...menuProps}
      collection={items}
      layout={layout}
    >
      {(type, item) => {
        return <ItemRow item={item as MenuNode} state={state} isVirtualized />;
      }}
    </Virtualizer>
  ) : (
    <Box
      ref={ref}
      bg={isDark ? 'canvas-dialog' : 'canvas-pure'}
      w="full"
      style={menuNodesWidthStyles}
      py={size === 'lg' ? 3 : 2}
      className={cn(className, { 'sl-menu--pointer-interactions': pointerInteraction })}
      cursor
      overflowY="auto"
      display="inline-block"
      noFocusRing
      {...menuProps}
    >
      {Array.from(items).map((item, i) => (
        <ItemRow item={item} state={state} key={item.key || i} />
      ))}
    </Box>
  );
}

const ItemRow: React.FC<{ item: MenuNode; state: MenuState; isVirtualized?: boolean }> = ({
  item,
  state,
  isVirtualized,
}) => {
  if (isMenuGroup(item.value) || isMenuOptionGroup(item.value)) {
    return (
      <MenuSectionWrapper
        key={item.key}
        section={item as MenuNode<MenuOptionGroup | MenuGroup, MenuItem | MenuOptionItem>}
        state={state}
      />
    );
  }

  if (isMenuDivider(item.value)) {
    return <Divider key={item.key} />;
  }

  return <MenuItemWrapper key={item.key} item={item} state={state} isVirtualized={isVirtualized} />;
};

function Divider() {
  const {
    separatorProps: { color, ...separatorProps },
  } = useSeparator({ elementType: 'div' });

  const { size } = useContext(MenuContext);

  return <Box my={size === 'lg' ? 2.5 : 2} borderT {...separatorProps} />;
}

interface MenuSectionWrapperProps {
  section: MenuNode<MenuOptionGroup | MenuGroup, MenuItem | MenuOptionItem>;
  state: MenuState;
  onAction?: (key: Key) => void;
}

export function MenuSectionWrapper({ section, state }: MenuSectionWrapperProps) {
  const {
    itemProps,
    headingProps: { color, ...headingProps },
    groupProps,
  } = useMenuSection({
    heading: section.rendered,
    'aria-label': section['aria-label'],
  });

  const { size, closeOnPress } = useContext(MenuContext);

  let value;
  let onChange;
  if (isMenuOptionGroup(section.value)) {
    value = section.value.value;
    onChange = section.value.onChange;
  }

  const childNodes = Array.from(section.childNodes);

  // do not render the section if it has no children
  if (!childNodes.length) return null;

  return (
    <>
      {/* If the section is not the first, add a separator element. */}
      {!section.firstInMenu && <Divider />}

      <div {...itemProps}>
        {section.rendered && (
          <Box
            {...headingProps}
            pl={size === 'lg' ? 5 : 3}
            pt={size === 'lg' ? 1 : 0.5}
            pb={size === 'lg' ? 1.5 : 1}
            pr={8}
            textTransform="uppercase"
            color="light"
            cursor
            fontSize={size === 'lg' ? 'base' : 'sm'}
          >
            {section.rendered}
          </Box>
        )}

        <div {...groupProps}>
          {childNodes.map((node, i) => {
            if (isMenuDivider(node.value)) {
              return <Divider key={node.key || i} />;
            }

            if (isMenuOption(node.value) && isMenuOptionGroup(section.value)) {
              node.value = {
                closeOnPress: typeof closeOnPress !== 'undefined' ? closeOnPress : false,
                ...node.value,
                isChecked: value === node.value.value,
                onPress: () => {
                  if (isMenuOption(node.value)) {
                    onChange(node.value.value);
                  }
                },
              };
            }

            // @ts-expect-error
            return <MenuItemWrapper key={node.key || i} item={node} state={state} isRadio={!!onChange} />;
          })}
        </div>
      </div>
    </>
  );
}

interface MenuItemWrapperProps {
  item: MenuNode;
  state: MenuState;
  isVirtualized?: boolean;
  onAction?: (key: Key) => void;
  isRadio?: boolean;
}

function MenuItemWrapper({ item, state, isRadio, isVirtualized }: MenuItemWrapperProps) {
  const ref = useRef();
  const { key, hasChildNodes, isDisabled } = item;
  const { useIsFocusedKey, useIsExpandedKey, toggleKey } = state;
  const { onClose, closeOnPress: globalCloseOnPress, size, cursor } = useContext(MenuContext);

  const hasSubmenu = hasChildNodes;

  const {
    isChecked: isSelected,
    title,
    value,
    onPress,
    label,
    isActive,
    closeOnPress,
    afterRestoreFocus,
    ...restItemProps
  } = item.value;

  const isExpanded = useIsExpandedKey(key);
  const isFocused = useIsFocusedKey(key);

  let closeOnSelect = false;
  // priority to the value set on the item itself, if one is set
  if (typeof closeOnPress !== 'undefined') {
    closeOnSelect = closeOnPress;
  } else {
    // else default to true when NOT in checked/radio context and NOT a submenu, otherwise the global default
    closeOnSelect = typeof isSelected !== 'undefined' ? globalCloseOnPress : !hasSubmenu;
  }

  // links use `onClick` handlers, while most of menu works off of `onmouseup`. This dynamic causes the onClose()
  // logic to teardown the menu before link `onClick` event is triggered, causing the link to not work
  // disable closeOnSelect for links to prevent this - we call onClose in the onAction handler below for links.
  const isLink = !!item.value.href;
  if (isLink) {
    closeOnSelect = false;
  }

  const onAction = useCallback(() => {
    if (onPress) {
      if (afterRestoreFocus) {
        requestAnimationFrame(() => setTimeout(() => onPress(key), 10));
      } else {
        onPress(key);
      }
    }

    if (isLink && onClose) {
      setTimeout(onClose, 0);
    }
  }, [onPress, isLink, onClose, afterRestoreFocus, key]);

  const handleHoverChange = React.useCallback(() => {
    toggleKey(key);
  }, [key, toggleKey]);

  const { hoverProps } = useHover({ onHoverStart: handleHoverChange, isDisabled });

  const { menuItemProps } = useMenuItem(
    {
      key,
      isSelected,
      isDisabled,
      onAction,
      closeOnSelect,
      onClose,
      isVirtualized,
    },
    state,
    ref,
  );

  let role = 'menuitem';
  if (isRadio) {
    role = 'menuitemradio';
  } else if (typeof isSelected !== 'undefined') {
    role = 'menuitemcheckbox';
  }

  const ariaProps = { role };
  if (typeof isSelected !== 'undefined') {
    ariaProps['aria-checked'] = isSelected;
  }

  const { color, ...restProps } = mergeProps(restItemProps, menuItemProps, hoverProps);

  const menuItemRowProps: MenuItemRowProps = {
    ...restProps,
    ...ariaProps,
    title: title || value,
    isFocused,
    isActive: isActive || isExpanded,
    isSelected,
    isDisabled,
    size,
    cursor,
  };

  if (!hasSubmenu) {
    return <Internal_MenuItemRow {...menuItemRowProps} ref={ref} />;
  }

  return (
    <SubmenuItem
      item={item}
      menuItemRowProps={menuItemRowProps}
      menuItemRef={ref}
      isExpanded={isExpanded}
      label={label}
      onClose={onClose}
    />
  );
}

type SubmenuItemProps = {
  item: MenuItemWrapperProps['item'];
  menuItemRowProps: MenuItemRowProps;
  menuItemRef: RefObject<undefined>;
  isExpanded: boolean;
  label?: string;
  onClose?: MenuContextProps['onClose'];
};

function SubmenuItem({ item, menuItemRowProps, menuItemRef, isExpanded, label, onClose }: SubmenuItemProps) {
  const triggerId = useId();
  const submenuId = useId();
  const { providerRef } = useMosaicContext();

  const ariaTriggerProps = {
    'aria-haspopup': true,
    'aria-expanded': isExpanded ? 'true' : 'false',
  };

  if (isExpanded) {
    ariaTriggerProps['aria-controls'] = submenuId;
  }

  return (
    <>
      <Internal_MenuItemRow {...menuItemRowProps} {...ariaTriggerProps} id={triggerId} hasSubmenu ref={menuItemRef} />
      {isExpanded ? (
        <Popover
          isOpen
          placement="right top"
          triggerRef={menuItemRef}
          boundaryElement={providerRef.current}
          contain={false}
          autoFocus={false}
          restoreFocus={false}
          appearance="minimal"
          offset={0}
          crossOffset={menuItemRowProps.size === 'lg' ? -12 : -8}
          onClose={onClose}
          type="menu"
          isNonModal
        >
          <MenuNodes
            id={submenuId}
            className="sl-menu sl-menu--submenu"
            aria-label={label || `${menuItemRowProps.title} submenu`}
            items={item.childNodes}
            aria-labelledby={triggerId}
          />
        </Popover>
      ) : null}
    </>
  );
}
