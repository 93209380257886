import { faRobot } from '@fortawesome/free-solid-svg-icons';
import * as React from 'react';

import { IIconProps } from '../../components/Icon';
import { BackgroundColorVals, BorderColorVals } from '../../enhancers';
import { useThemeMode } from '../../hooks/use-theme-mode';
import { Box } from '../Box';
import { Flex } from '../Flex';
import { Icon } from '../Icon';
import { Text } from '../Text';

type CardProps = {
  title: string | React.ReactNode;
  description?: string | React.ReactNode;
  logoIcon?: IIconProps['icon'];
  logoIconColor?: IIconProps['color'];
  logoBackgroundColor?: BackgroundColorVals;
  actionBar?: React.ReactNode;
  badge?: React.ReactNode;
  isActive?: boolean;
  activeHighlightColor?: BorderColorVals;
};

export const Card = ({
  title,
  description,
  logoIcon = faRobot,
  logoIconColor = '#A0AEC0',
  logoBackgroundColor = 'canvas-100',
  actionBar,
  badge,
  isActive = false,
  activeHighlightColor = 'success',
}: CardProps) => {
  const { mode } = useThemeMode();

  return (
    <Box w="full" boxShadow="xl" rounded="lg" display="inline-block" cursor="pointer">
      <Box
        border={2}
        borderColor={{
          default: isActive ? activeHighlightColor : 'transparent',
        }}
        m={-0.5}
        rounded="lg"
        overflowX="hidden"
        overflowY="hidden"
      >
        <Flex bg={logoBackgroundColor} pos="relative" alignItems="center" justifyContent="center" h={24}>
          <Box pos="absolute" right={0} top={0} textAlign="right" px={2} py={1} color="success">
            {badge}
          </Box>
          <Icon icon={logoIcon} color={logoIconColor} size="3x" />
        </Flex>
        <Box
          style={{ backgroundColor: mode === 'dark' ? 'var(--color-canvas-50)' : 'var(--color-canvas)' }}
          bg="canvas"
          border={0}
          borderColor="transparent"
          p={4}
        >
          <Text fontSize="base" color="body" fontWeight="semibold">
            {title}
          </Text>
          <Text
            fontSize="sm"
            color="muted"
            overflowX="hidden"
            overflowY="hidden"
            style={{ display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}
          >
            {description}
          </Text>
        </Box>
        {actionBar && (
          <Box bg="canvas" w="full" overflowY="hidden" overflowX="hidden" borderT={true}>
            {actionBar}
          </Box>
        )}
      </Box>
    </Box>
  );
};
