import cn from 'classnames';
import FocusTrap from 'focus-trap-react';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import { useMountTransition } from '../../hooks/use-mount-transition';

function createPortalRoot() {
  const drawerRoot = document && document.createElement('div');
  drawerRoot.setAttribute('id', 'drawer-root');

  return drawerRoot;
}

type DrawerDirection = 'right' | 'left' | 'top' | 'bottom';

export type DrawerProps = {
  id?: string;
  isOpen: boolean;
  children: JSX.Element | JSX.Element[] | React.ReactNode;
  className?: string;
  onClose?: () => void;
  position?: DrawerDirection;
  removeWhenClosed?: boolean;
};

export const useDrawerState = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>();

  return {
    isOpen: isOpen,
    setIsOpen: () => setIsOpen(!isOpen),
  };
};

export const Drawer = ({
  id,
  isOpen,
  children,
  className,
  onClose,
  position = 'left',
  removeWhenClosed = true,
}: DrawerProps) => {
  const body = document && document.querySelector('body');
  const bodyRef = useRef(body);

  const portalRoot = (document && document.getElementById('drawer-root')) || createPortalRoot();
  const portalRootRef = useRef(portalRoot);

  const isTransitioning = useMountTransition({ isMounted: isOpen, unmountDelay: 300 });

  // Append portal root on mount
  useEffect(() => {
    bodyRef.current.appendChild(portalRootRef.current);
    const portal = portalRootRef.current;
    const bodyEl = bodyRef.current;

    return () => {
      // Clean up the portal when drawer component unmounts
      portal.remove();
      // Ensure scroll overflow is removed
      bodyEl.style.overflow = '';
    };
  }, []);

  // Prevent page scrolling when the drawer is open
  useEffect(() => {
    const updatePageScroll = () => {
      if (isOpen) {
        bodyRef.current.style.overflow = 'hidden';
      } else {
        bodyRef.current.style.overflow = '';
      }
    };

    updatePageScroll();
  }, [isOpen]);

  // Allow Escape key to dismiss the drawer
  useEffect(() => {
    const onKeyPress = e => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      window.addEventListener('keyup', onKeyPress);
    }

    return () => {
      window.removeEventListener('keyup', onKeyPress);
    };
  }, [isOpen, onClose]);

  if (!isTransitioning && removeWhenClosed && !isOpen) {
    return null;
  }

  return createPortal(
    <FocusTrap active={isOpen}>
      <div
        aria-hidden={isOpen ? 'false' : 'true'}
        className={cn('drawer-container', {
          open: isOpen,
          in: isTransitioning,
          className,
        })}
      >
        <div className={cn('drawer', position)} role="dialog">
          {children}
        </div>
        <div className="backdrop" onClick={onClose} />
      </div>
    </FocusTrap>,
    portalRootRef.current,
  );
};
