import cn from 'clsx';
import * as React from 'react';
import { forwardRef, memo } from 'react';

import { IColorProps, IMarginProps, IPaddingProps, SpaceVals } from '../../enhancers';
import { Box } from '../Box';
import { IBoxHTMLAttributes } from '../Box/types';

export type ContainerSizeVals = 'full' | 'xl' | 'lg' | 'md' | 'sm' | 'xs';

export interface IContainerProps extends IMarginProps, IPaddingProps, IBoxHTMLAttributes, IColorProps {
  as?: React.ElementType;
  size?: ContainerSizeVals;
}

export const containerSizes: Record<ContainerSizeVals, { maxWidth?: number; p: SpaceVals }> = {
  xs: {
    maxWidth: 300,
    p: 3,
  },
  sm: {
    maxWidth: 640,
    p: 3,
  },
  md: {
    maxWidth: 950,
    p: 3,
  },
  lg: {
    maxWidth: 1280,
    p: 5,
  },
  xl: {
    maxWidth: 1450,
    p: 5,
  },
  full: {
    p: 5,
  },
};

export const Container = memo(
  forwardRef<HTMLDivElement, IContainerProps>(function Container(
    { size = 'full', className, style = {}, ...props },
    ref,
  ) {
    return (
      <Box
        ref={ref}
        px={containerSizes[size].p}
        mx="auto"
        w="full"
        style={{
          maxWidth: containerSizes[size].maxWidth,
          ...style,
        }}
        className={cn('Container', className)}
        {...props}
      />
    );
  }),
);
